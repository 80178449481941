
import React from 'react';
import Services from '../components/Services';

const ServicePage = () => {
  return (
    <div>
      <Services />
    </div>
  );
};

export default ServicePage;
